// Import the classes from the other file
import Command from './commands.js';


export default class Thermostat {

  constructor(propId, commandConfig, deviceInfo, authInfo) {

    // build the command structure for the thermostat based on the whole config
    this.commandStructure = {

      //auth section
      user_id: parseInt(authInfo.user_id),
      sk: authInfo.sk,
      user_email: authInfo.email,

      target_unique_id: parseInt(commandConfig.unique_id),
      target_property_id: propId,
      target_dev_eui: commandConfig.eui,

      target_hostname_list: [commandConfig.last_hostname],
      qr: commandConfig.qr,
      command_token: "sitemanager@6cXZ3xrAi3dRxMqDvJY75pMMwEizIDs68r57l4pP",
      unit_code: deviceInfo.meta1.header_zigbee_unit_code,
      

    }

    this.deviceInfo = deviceInfo;
    this.availableCommands = [],


    console.log('new thermostat details', this.commandStructure)
    console.log('new thermostat device info', this.deviceInfo);

  }

  
  getCommands() {
  
    for (let i = 0; i < Thermostat.commands.length; i++) {
      
      if (Thermostat.commands[i].allowedQr.includes(this.commandStructure.target_dev_eui)) {
        
          this.availableCommands.push(Thermostat.commands[i]); 
     
      }
      
    }

  }

  ping(){
   
    if(this.deviceInfo.meta2.real_time_status !== 'offline' || this.deviceInfo.meta2.real_time_status !== 'unconfirmed') {
      this.sendCommand(Thermostat.commands[0].name, {'jitter':false}); 
    }
    
  }

  static tstatQrs =  [92, 105, 36, 33, 34, 35, 67, 1006, 1009,118];

  // perhaps put this in some sort of database or config file
  static commands = [

    // 0
    {
      name: "ping",
      title: "Refresh",
      global: false,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: false,
      payloads: [
        {
          payloadName: 'jitter',
          payloadTitle: 'Jitter',
          
        }

      ],
    },
    // 1
    {
      name: "set_mode",
      title: "Change Mode",
      globalName_heat: "Change Mode",
      globalName_cool: "Change Mode",
      global: true,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: false,
      payloads: [
        {
          payloadName: "mode",
          payloadTitle: "Mode",
          options: [
            {
              optionName: 'heat',
              optionTitle: 'Heat',
              allowedQr: [92, 105, 36, 33, 34, 35, 67],
            },
            {
              optionName: 'cool',
              optionTitle: 'Cool',
              allowedQr: [92, 33, 34, 35, 67],
            },
            {
              optionName: 'off',
              optionTitle: 'Off',
              allowedQr: [92, 105, 36, 33, 34, 35, 67],
            }
          ]
        }
      ]
    },
    // 2
    {
      name: "set_heat_equipment",
      title: "Set Heat Equipment",
      global: false,
      allowedQr: [92, 105, 36, 33, 34, 35, 67],
      special: true,
      payloads: [
        {
          payloadName: "equipment",
          payloadTitle: "Equipment",
          options: [
            {
              optionName: 'heat_pump',
              optionTitle: 'Heat Pump',
              allowedQr: [92, 105, 36, 33, 34, 35, 67],
            },
            {
              optionName: 'none',
              optionTitle: 'None',
              allowedQr: [92, 33, 34, 35, 67],
            },
            {
              optionName: 'furnace',
              optionTitle: 'Furnace',
              allowedQr: [92, 33, 34, 35, 67],
            },
            {
              optionName: 'strip',
              optionTitle: 'Strip',
              allowedQr: [105, 36],
            }
          ]
        }
      ]
    },

    // 3
    {
      name: "set_cool_equipment",
      title: "Set Cool Equipment",
      globalName_heat: "Change Heat Equipment",
      globalName_cool: "Change Cool Equipment",
      global: true,
      allowedQr: [33, 34, 35, 67],
      special: true,
      payloads: [
        {
          payloadName: "equipment",
          payloadTitle: "Equipment",
          options: [
            {
              optionName: 'pump',
              optionTitle: 'Pump',
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: 'condensor',
              optionTitle: 'Condensor',
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: 'strip',
              optionTitle: 'Strip',
              allowedQr: [105, 36],
            }
          ]
        }
      ]
    },
    /*
    {
      name: "set_lockout",
      title: "Set Lockout",
      allowedQr: [92, 105],
      special: true,
      payloads: [
        {
          payloadName: "lock",
          payloadTitle: "Lock",
          options: [
            {
              optionName: 'temperature',
              optionTitle: 'Temperature',
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: 'mode',
              optionTitle: 'Mode',
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: 'equipment',
              optionTitle: 'Equipment',
              allowedQr: [105, 36],
            }
          ]
        }
      ]
    },
    */

    // 4
    {

      name: "set_schedule_mode",
      title: "Set Schedule Mode",
      global: false,
      allowedQr: [33, 34, 35, 67],
      special: false,
      payloads: [
        {
          payloadName: "schedule_mode",
          payloadTitle: "Schedule Mode",
          options: [
            {
              optionName: "permanent",
              optionTitle: "Permanent",
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: "temporary",
              optionTitle: "Temporary",
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: "program",
              optionTitle: "Program",
              allowedQr: [33, 34, 35, 67],
            },
            {
              optionName: "vacation",
              optionTitle: "Vacation",
              allowedQr: [33, 34, 35, 67],
            }
          ]
        }
      ],

    },
    /*
    {

      name: "filter",
      title: "Filter",
      allowedQr: [92, 105],
      special: true,
      payloads: [
        {
          payloadName: "set_filter",
          payloadTitle: "Set Filter",
          options: [
            {
              optionName: "idk1",
              optionTitle: "idk1",
              allowedQr: [92, 105],
            },
            {
              optionName: "idk2",
              optionsTitle: "idk2",
              allowedQr: [92, 105],
            },
            {
              optionName: "idk3",
              optionTitle: "idk3",
              allowedQr: [92, 105],
            },
            {
              optionName: "idk4",
              optionTitle: "idk4",
              allowedQr: [92, 105],
            }
          ]
        }
      ],

    },

*/
    // 5
    {
      name: "set_tx_interval",
      title: "Set Tx Interval",
      global: false,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: false,
      payloads: [
        {
          payloadName: "health_seconds",
          payloadTitle: "Health Seconds",
          options: Array.from({ length: 9000 - 30 + 1 }, (_, i) => 30 + i), //30 - 9000 seconds
          allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
        },

        {
          payloadName: "commidity_seconds",
          payloadTitle: "Commidity Seconds",
          options: Array.from({ length: 9000 - 30 + 1 }, (_, i) => 30 + i), //30 - 9000 seconds
          allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
        },
      ]
    },

    // 6
    {
      name: "set_fan_mode",
      title: "Set Fan Mode",
      global: false,
      allowedQr: [118,33,34,35,67,1009],
      special: false,
      payloads: [
        {
          payloadName: "fan_mode",
          payloadTitle: "Mode",
          options: [
            {
              optionName: "on",
              optionTitle: "On",
              allowedQr: [118,33,34,35,67,34,1009],
            },
            {
              optionName: "auto",
              optionTitle: "Auto",
              allowedQr: [118,33,34,35,67,34,1009],
            },
            {
              optionName: "intermittent",
              optionTitle: "Intermittent",
              allowedQr: [118, 34, 35, 67,1009],
            },
            {
              optionName: "followprogram",
              optionTitle: "Follow Program",
              allowedQr: [118, 34, 35, 67,1009],
            }
          ]
        }
      ]
    },

    // 7
    {
      name: "set_set_point",
      title: "Change Thermostat Set Point",
      globalName_heat: "Change Thermostat Heat Set Point",
      globalName_cool: "Change Thermostat Cool Set Point",
      global: true,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: false,
      payloads: [
        
        {
          payloadName: "mode",
          payloadTitle: "Mode",
          options: [
            {
              optionName: "heat",
              optionTitle: "Heat",
              allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
            },
            {
              optionName: "cool",
              optionTitle: "Cool",
              allowedQr: [92, 33, 34, 35, 67, 1001, 1006, 1009],
            }
          ]
        },
        {
          payloadName: "temperature_f",
          payloadTitle: "Temperature",
          options: Array.from({ length: 90 - 45 + 1 }, (_, i) => 45 + i),
        },

      ]
    },

    // 8
    {
      name: "get_zone_settings",
      title: "Zone Settings",
      global: false,
      allowedQr: [105, 36],
      special: true,
      payloads: [{}],
        
    },

    // 9
    {
      name: "set_zone_settings",
      title: "Zone Settings",
      globalName_heat: "Change Zone Settings",
      globalName_cool: "Change Zone Settings",
      global: true,
      allowedQr: [105, 36],
      special: true,
      payloads: [
        {
          payloadName: "zone_1_hour_24hr",
          payloadTitle: "Zone 1 Hour",
          options: Array.from({ length: 24}, (_, i) => i),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_1_minute",
          payloadTitle: "Zone 1 Minute",
          options: Array.from({ length: 59}, (_, i) => i),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_1_set_point_f",
          payloadTitle: "Zone 1 Set Point",

          options: Array.from({ length: ((90 - 45)) }, (_, i) => 90 - (i)),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_1_set_limit_f",
          payloadTitle: "Zone 1 Set Limit",
          options: Array.from({ length: ((90 - 45)) }, (_, i) => 90 - (i)),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_2_hour_24hr",
          payloadTitle: "Zone 2 Hour",
          options: Array.from({ length: 24}, (_, i) => i),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_2_minute",
          payloadTitle: "Zone 2 Minute",
          options: Array.from({ length: 59}, (_, i) => i),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_2_set_point_f",
          payloadTitle: "Zone 2 Set Point",
          options: Array.from({ length: ((90 - 45)) }, (_, i) => 90 - (i)),
          allowedQr: [105, 36],
        },
        {
          payloadName: "zone_2_set_limit_f",
          payloadTitle: "Zone 2 Set Limit",
          options: Array.from({ length: ((90 - 45)) }, (_, i) => 90 - (i)),
          allowedQr: [105, 36],
        },
      ]
    },

    /*
      payloads: [{

        "zone_1_hour_24hr" : Array.from({ length: 23 - 35 + 1 }, (_, i) => 1 + i),
        
        "zone_1_minute": Array.from({ length: 60 - 35 + 1 }, (_, i) => 35 + i),
        
        "zone_1_set_point_f" : Array.from({ length: 100 - 60 + 1 }, (_, i) => 60 + i),
        
        "zone_1_set_limit_f" : Array.from({ length: 100 - 60 + 1 }, (_, i) => 60 + i),
        
        "zone_2_hour_24hr" : Array.from({ length: 23 - 35 + 1 }, (_, i) => 1 + i),
        
        "zone_2_minute" : Array.from({ length: 60 - 35 + 1 }, (_, i) => 35 + i),
        
        "zone_2_set_point_f" : Array.from({ length: 100 - 60 + 1 }, (_, i) => 60 + i),
        
        "zone_2_set_limit_f" : Array.from({ length: 100 - 60 + 1 }, (_, i) => 60 + i)

      }],
      */
        
    // 10
    {
      name: "get_set_point_limit",
      title: "Get Set Point Limits",
      global: false,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: true,
      payloads:[{
        payloadName: "mode",
        payloadTitle: "Mode",
        options: [
          {
            optionName: "heat",
            optionTitle: "Heat",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          },
          {
            optionName: "cool",
            optionTitle: "Cool",
            allowedQr: [92, 33, 34, 35, 67, 1001, 1006, 1009],
          }, 
          {
            optionName: "both",
            optionTitle: "Both",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          }
        ]
      }],

    },

    // 11
    {
      name: "set_set_point_limit",
      title: "Change Set Point Limits",
      globalName_heat: "Change Heat Set Point Limits",
      globalName_cool: "Change Cool Set Point Limits",
      global: true,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: true,
      payloads: [{
        payloadName: "mode",
        payloadTitle: "Mode",
        options: [
          {
            optionName: "heat",
            optionTitle: "Heat",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          },
          {
            optionName: "cool",
            optionTitle: "Cool",
            allowedQr: [92, 33, 34, 35, 67, 1001, 1006, 1009],
          },
        ]
      },
      {
        payloadName: "temperature_f",
        payloadTitle: "Temperature",
        options: Array.from({ length: 90 - 45 + 1 }, (_, i) => 45 + i),
      }
    
    ],

      

    },

    // 12
    {
      name: "get_equipment",
      title: "Get Equipment",
      global: false,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: true,
      payloads: [{
        payloadName: "mode",
        payloadTitle: "Mode",
        options: [
          {
            optionName: "heat",
            optionTitle: "Heat",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          },
          {
            optionName: "cool",
            optionTitle: "Cool",
            allowedQr: [92, 33, 34, 35, 67, 1001, 1006, 1009],
          },
          {
            optionName: "both",
            optionTitle: "Both",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          }
        ]
      }],

    },

    // 13
    {
      name: "set_equipment",
      title: "Set Equipment",
      globalName_heat: "Change Heat Equipment",
      globalName_cool: "Change Cool Equipment",
      global: true,
      allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
      special: true,
      payloads: [{
        payloadName: "mode",
        payloadTitle: "Mode",
        options: [
          {
            optionName: "heat",
            optionTitle: "Heat",
            allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
          },
          {
            optionName: "cool",
            optionTitle: "Cool",
            allowedQr: [33, 34, 35, 67, 1001, 1006, 1009],
          },
          
        ]
      },
        {
          payloadName: "type",
          payloadTitle: "Type",
          options: [
            {
              optionName: "yes",
              optionTitle: "Yes",
              allowedQr: [ 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
            },
            {
              optionName: "none",
              optionTitle: "None",
              allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
            },
            {
              optionName: "furnace",
              optionTitle: "Furnace",
              allowedQr: [92, 33, 34, 35, 67, 1001, 1006, 1009],
            },
            {
              optionName: "strip",
              optionTitle: "Strip",
              allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
            },
            {
              optionName: "heat_pump",
              optionTitle: "Heat Pump",
              allowedQr: [92, 105, 36, 33, 34, 35, 67, 1001, 1006, 1009],
            }
          ]
        }
    
    ],

    },


   


  ]

  chartOptions = {
    responsive: true,
    tension: 0,
    scales: {
      x: {
        type: 'time',
      },
      y: {
        ticks: {
          callback: function (value) {
            return value.toFixed(0) + '°F'; // Add the Fahrenheit symbol to the label
          }
        }
      }
    },
    plugins: {
      legend: {
        postion: 'bottom',
      },
    }
  }

  async sendCommand(commandName, formData) {

      this.commandStructure.unit_code = this.deviceInfo.meta1.header_zigbee_unit_code;
      this.commandStructure.target_device_type = this.deviceInfo.meta1.header_common_device_type;
    
      // show what the command config looks like before we send it
      //console.log('command config before commands', this.commandStructure);

      Object.keys(formData).forEach(key => {
        JSON.stringify(key);
    });

    
    //console.log('commandName', commandName);
    //console.log('formData', formData);
    

    // assign the params to vars so we can validate them and use them

    //console.log(`Command =>  ${commandName}`);
    //console.log('isSpecial Command => ', Thermostat.commands.find(x => x.name === commandName).special);

    this.commandStructure.downlink_payload = formData;
    this.commandStructure.command = commandName;
    this.commandStructure.isSpecial = Thermostat.commands.find(x => x.name === commandName).special;

    // make it json for the backend processing 
    var stringCommand = JSON.stringify(this.commandStructure);

    //console.log('stringCommand', stringCommand);

    //send it to the commands class (websocket connection) 
    var command = new Command(stringCommand);
    command.send();


  }


}
